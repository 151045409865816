<template>
    <div class="border-l-2 border-primary circle active relative pl-6 flex flex-wrap w-full items-start pb-12 md:pb-20">
      
      <div class="w-full md:w-6/12 md:pr-20"> 
          <p class="text-primary font-secondary uppercase text-sm mb-3">{{tr('DAY')}} {{day.dayNr}} | {{ day.dateLong}}</p>
          <h3 class="font-secondary text-2xl text-dark" v-if="day.services && day.services[0]">{{day.services[0].locationName}}</h3>
          <div v-for="(s, index) of day.services" :key="index">
            <p class="mt-3 destiny-subtitle font-bold relative ml-5" v-if="titles[index]">
              {{titles[index]}}
            </p>
            <div v-if="readMore" class="font-light leading-tight">
              <p class="mb-3" v-html="s.description">
              </p>
            </div>
          </div>
          <a class="text-primary font-light inline-block mb-3 hover:underline cursor-pointer mt-3" @click="readMore = !readMore">
            <span v-if="!readMore">{{tr('Read more')}}</span> 
            <span v-else>{{tr('Read less')}}</span> 
          </a>
      </div>
      <div class="w-full md:w-6/12"> 
        <DaySwiper
          imgClass="img-servcie rounded-xl"
          :images="images"
          v-if="images.length > 0"
        ></DaySwiper>
      </div>
    </div>
</template>

<script>
const s3 = process.env.VUE_APP_S3;
import * as _ from 'lodash';
import { HTMLToJSON } from 'html-to-json-parser'; // ES6
import DaySwiper from '@/components/itinerary/DaySwiper.vue';
export default {
  name: 'TripDayServices',
  props: {
    day: Object
  },
  data () {
    return {
      booking: null,
      readMore: false,
      images: [],
      titles: {}
    }
  },
  async mounted () {
    this.images = [];
    for (let s of this.day.services) {
      let img = `${s3}/OPT_ID.${s.code}.0.html`;
      this.images.push({url: img});
    }
    this.images = Object.assign([], this.images);
    for (let i=0; i<this.day.services.length; i++) {
      if (!this.day.services[i].title) continue;
      let res = await this.getTitle(this.day.services[i].title);
      if (res) {
        this.titles[i] = res;     
      }
    }
    this.titles = Object.assign({}, this.titles);
  },
  methods: {
    async getImagePath (url) {
        await Promise.resolve(import(`@/assets/${url}`)).then((value) => {
            this.images.push({url: value.default})
        });
    },
    async getTitle (title) {
      return await this.extractText(title);
    },
    tr(key) {
      return this.$root.tr(key);
    },
    async extractText(node) {
      let res = await HTMLToJSON(node.replace(/<br>/g, '<br/>'), false);
      if (res.type === '#text') {
        return node;
      }
      if (res.content && res.content[0] && res.content[0].content) {
        return _.filter(res.content[0].content, (r) => r.type != 'br')[0];     
      }
    }
  },
  components: {
    DaySwiper,
  }
}
</script>
