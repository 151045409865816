<template>
    <div class="w-full grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 items-end border-b-2 border-[#4C535966]">
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Name (as passport)<span class="ml-2 text-primary">*</span></label>
        <input type="text" v-model="pax.name" @change="update" :class="{'error': errors.name}"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>

      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Last Name (As Passport)<span class="ml-2 text-primary">*</span></label>
        <input type="text" v-model="pax.lastName" @change="update"  :class="{'error': errors.lastName}"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3 "  />
      </div>

      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Date of birth<span class="ml-2 text-primary">*</span></label>
        
        <div class="relative w-full">
          <Datepicker :teleport="true" :value="pax.DOB" @selected="updateDOB" @input="updateDOB" :class="{'error': errors.DOB}"
            class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-1 "></Datepicker>
        </div>
      </div>

        <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Gender</label>
        

        <select id="gender" v-model="pax.gender" @change="update" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
          <option :selected="pax.gender == 'MALE'" value="MALE">Male</option>
          <option :selected="pax.gender == 'FEMALE'" value="FEMALE">Female</option>
        </select>
      </div>

      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Nationality<span class="ml-2 text-primary">*</span></label>
        <select id="countries" v-model="pax.nationality"  :class="{'error': errors.nationality}" @change="update"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
          <option v-for="(c, i) of countriesJSON" :key="i" :selected="c.name == pax.nationality">{{ c.name }}</option>
        </select>
      </div>


      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Valid Passport Number<span class="ml-2 text-primary">*</span></label>
        <input v-model="pax.passport" @change="update" type="text" :class="{'error': errors.passport}"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Address</label>
        <input v-model="pax.address" @change="update" type="text"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Email<span class="ml-2 text-primary">*</span></label>
        <input v-model="pax.email" @change="update" type="email" :class="{'error': errors.email}"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Cellphone<span class="ml-2 text-primary">*</span></label>
        <input v-model="pax.mobile" @change="update" type="text" :class="{'error': errors.mobile}"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>
      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Main reason of your trip</label>
        

        <select id="hobbies" v-model="pax.tripReason"  @change="update"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
          <option v-for="(c, i) of tripReasons" :key="i" :selected="c == pax.tripReason">{{ c }}</option>
        </select>
      </div>




      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Allergies</label>
        <input v-model="pax.allergies" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " placeholder="If yes, which?" />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Medical Conditions</label>
        <input v-model="pax.medicalConditions" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " placeholder="If yes, which?" />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Medication</label>
        <input v-model="pax.medictation" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " placeholder="If yes, which?" />
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Food Restriction</label>
        <input v-model="pax.foodRestriction" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " placeholder="Celiac, vegetarian, etc." />
      </div>
      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Hobbies</label>
        <select id="hobbies" v-model="pax.hobbies"  @change="update"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
          <option v-for="(c, i) of hobbies" :key="i" :selected="c == pax.hobbies">{{ c }}</option>
        </select>
      </div>



      <div class="mb-5 sm:col-span-2">
        <div class="inline-block">
          <label for="file-upload" class=" cursor-pointer pb-2 border-b border-black uppercase font-semibold text-sm flex w-auto">
            <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5303 0.96967C13.2374 0.676777 12.7626 0.676777 12.4697 0.96967L7.6967 5.74264C7.40381 6.03553 7.40381 6.51041 7.6967 6.8033C7.98959 7.09619 8.46447 7.09619 8.75736 6.8033L13 2.56066L17.2426 6.8033C17.5355 7.09619 18.0104 7.09619 18.3033 6.8033C18.5962 6.51041 18.5962 6.03553 18.3033 5.74264L13.5303 0.96967ZM13.75 11.5L13.75 1.5L12.25 1.5L12.25 11.5L13.75 11.5Z" fill="#1F2326"/>
            </svg>
            Please upload an image of your Passport

          </label>
          <input id="file-upload" class="hidden" type="file" @change="uploadPassport($event)" multiple />
          <div v-for="(file, i) of pax.files" :key="i">
            {{file.name}}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker';
let countriesJSON = require(`@/assets/world.json`);
var moment = require('moment');
export default {
  name: 'BookingFromPax',
  data() {
    return {
      countriesJSON: countriesJSON,
      pax: {},
      hobbies: [
        'Historical',
        'Cultural',
        'Wine',
        'Trekking',
        'Fishing',
        'Tango',
        'Geography',
        'Others',
      ],
      tripReasons: [
        'Birthday / Anniversary',
        'Honeymoon',
        'Tourism',
        'Adventure trips',
        'Others',
      ],
      limitSize: 10000000,
    }
  },
  components: {
    Datepicker
  },
  mounted () {
    this.pax = Object.assign({}, this.passenger);
  },
  props: {
    passenger: Object,
    errors: Object,
  },
  methods: {
    update () {
      this.$emit('update', this.pax)
    },
    updateDOB (a) {
      this.pax.DOB = moment(a).format('YYYY-MM-DD');
      this.update();
    },
    checkFileSize (file) {
        if (file.size>this.limitSize) {
            return;
        }
        return true;
    },
    uploadPassport (event) {
        let input = event.target;
        if (input.files) {
            let err;
            for (let j = 0; j < input.files.length; j++) {
                if (!this.checkFileSize(input.files[j])) {
                    err = true;
                }
            }
            if (err) {
                input.files = null;
                alert(`Maximum allowed file size ${this.limitSize / 1000000} Mb`);
            } else {
              this.pax.files = input.files;
              this.update();
            }
        }
    },
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}

.error {
  border: 2px solid red;
}

</style>