//import Vue from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import Trip from './components/pages/Trip.vue';
import FIT from './components/pages/FIT.vue';
import Hotels from './components/pages/Hotels.vue';
import Index from './components/Index.vue';
import BookingForm from './components/pages/BookingForm.vue';
import Home from './components/pages/Home.vue';

const routes = [
  { path: '/:pathMatch(.*)*', name: 'not-found', component: Index 
  },
  {
    path: '/',
    name: 'Root',
    component: Index,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/trip',
    name: 'Trip',
    component: Trip,
    props: true,
  },
  {
    path: '/fit',
    name: 'FIT',
    component: FIT,
    props: true,
  },
  {
    path: '/hotels',
    name: 'Hotels',
    component: Hotels,
    props: true,
  },
  {
    path: '/bookingform',
    name: 'BookingForm',
    props: true,
    component: BookingForm,
  },
];

const router = new createRouter({
  history: createWebHistory(),
  routes,
});



router.afterEach((to, from) => {
  from
  if (to.path.split('/')[1]=='public') {
      document.title = process.env.APP_NAME;
      const link = document.querySelector("[rel='icon']")
      link.setAttribute('href', to.meta.icon)
  }

})


export default router;
