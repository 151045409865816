<template>
    <section>
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-20">
        <gr-section-why v-if="grUser" :userData="grUser"/>

        <div class="w-full md:w-6/12 md:pr-20 mb-12 md:mb-0" v-else>

          <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            {{tr('Why this trip is perfectly matched for you')}}
          </h2>

          <p class="text-dark font-normal text-md">
            <span v-if="coverNote" v-html="coverNote.MESSAGE_TEXT"></span>
          </p>
        </div>

        <div class="w-full md:w-6/12 border-2 border-primary rounded-xl pt-6 pb-12 md:pb-12 px-6 md:px-12">

          <div class=" relative text-center " style="top: -2.5rem">
            <h3 class="text-2xl bg-white px-3 text-primary font-primary font-black inline-block mx-auto text-center">{{tr('Why Say Hueque')}}</h3>
          </div>

          <div class="grid md:grid-cols-2 gap-6 justify-center items-center">

            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/iconocasa.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('Local know best')}}</span>
            </div>

            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/diamante.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('Elite reputation')}}</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/mano.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('24/7 here for you')}}</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/medalla.png" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('Best value')}}</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/recicla.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('Sustainable commitment')}}</span>
            </div>
            <div class=" flex items-center">
                <div class="w-auto mr-4">
                  <img src="@/assets/trip/img/pesos.svg" width="50px">
                </div>
                <span class="font-primary font-bold text-xl text-primary">{{tr('Local economies')}}</span>
            </div>
            
          </div>

        </div>

      </div>
    </section>  

</template>
<script>
import GrSectionWhy from './GrSectionWhy.vue';
import { HTMLToJSON } from 'html-to-json-parser'; // ES6
import * as _ from 'lodash';
export default {
  name: 'SectionWhy',
  props: {
  },
  components: {
    'gr-section-why':GrSectionWhy
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    lang () {
      return this.$store.state.lang;
    },
    coverNote () {
      return this.$store.state.coverNote;
    },
    grUser () {
      let data = null
      if (this.$store.state.grUser) data = Object.assign({}, this.$store.state.grUser);
      return data
    }
  },
  data () {
    return {
    }
  },
  methods: {
    tr(key) {
      return this.$root.tr(key);
    },

    async extractText(node) {
      let res = await HTMLToJSON(node.replace(/<br>/g, '<br/>'), false);
      if (res.type === '#text') {
        return node;
      }
      if (res.content && res.content[0] && res.content[0].content) {
        return _.filter(res.content[0].content, (r) => r.type != 'br')[0];     
      }
    }
  }
}
</script>
