<template>
  <div 
    :class="[
      fitPricesLength === 1 ? 'py-6 md:py-8 md:flex-nowrap 2xl:gap-24 gap-6 w-full flex flex-wrap h-full' : '',
      fitPricesLength === 2 ? 'py-6 md:py-8 2xl:flex-nowrap 2xl:gap-8 gap-6 w-full flex flex-wrap h-full' : '',
      fitPricesLength > 2 ? 'w-full flex mx-auto flex-wrap relative mb-4  md:mb-4' : ''
    ]"
    v-if="bookingType === 'FIT' && fitPrices"
  >
    <div 
      :class="[
        fitPricesLength === 1 ? 'h-full' : '',
        fitPricesLength > 2 ? 'w-full md:w-7/12 py-6 md:py-8 flex flex-wrap relative justify-center' : '',
        fitPricesLength === 2 ? 'hidden-parent' : ''
      ]"
    >
      <div 
        v-for="(prices, key) in fitPrices"
        :key="key"
        :class="[
          fitPricesLength === 1 ? 'hidden-parent' : '',
          fitPricesLength === 2 ? 'h-full 2xl:w-1/2 xl:w-1/3 mb-8 2xl:mb-0 grow' : '',
          fitPricesLength > 2 ? 'md:w-1/2 w-full mb-8 2xl:mb-6' : ''
        ]"
      >
        <h2 class="font-secondary text-xl 2xl:text-xl mb-4" v-if="fitPricesLength > 1 && fitPaxDetail == 'SI'">
          For <span class="text-primary">{{ prices.PaxName }}</span>
        </h2>
        <quote-card 
          :personPrice="prices.PriceTotal"
          :basesQty="fitPricesLength"
          :class="{ 'mr2': fitPricesLength > 2 }"
        />
      </div> 
    </div>

    <div 
      v-if="fitPricesLength <= 2"
      :class="[
        'w-full mt-3 md:mt-0',
        fitPricesLength === 1 ? 'md:w-9/12' : '',
      ]"
    >
      <div 
        :class="[
          'w-full mt-3 md:mt-0 border-4 border-light-gray bg-light-gray shadow-2xl p-4 md:p-6 flex flex-wrap justify-start items-start rounded-xl',
          fitPricesLength === 1 ? 'h-full items-start rounded-xl' : '',
          fitPricesLength === 2 ? 'h-full' : '',
        ]"
      >
        <div>
          <p 
            class="font-secondary mb-2"
          >
            Start your adventure with us!
          </p>
          <p 
            class="text-dark font-light md:pr-20"
          >
            If you are happy with the proposed quote, you can make a deposit of 
            <b class="font-bold text-primary">$<label>{{ totalFitPrice  }}</label> USD</b>, which represents 30% of the cost of your trip.
          </p>
        </div>
        <div 
          class="p-button self-end flex flex-wrap w-full md:mt-3"
          :class="[
            fitPricesLength === 1 ? 'md:flex-nowrap' : ''
          ]"
        >
          <paypal-button :amount="pricesList.paypalPrice" :bookingId="bookingId"/>
        </div>
      </div>
    </div>
    <div v-else class="w-full md:w-5/12 relative py-6 md:py-8 flex h-full flex-wrap md:pl-8 xl:mt-10 mt-0 pt-0 xl:mb-4">
      <div class="md:w-full w-full mt-3 md:mt-0 ">
        <div class="border-4 border-light-gray bg-light-gray shadow-2xl p-4 md:p-6 flex flex-wrap justify-start flex items-start rounded-xl md:sticky md:top-0 ">
          <div>
            <p class="font-secondary text-2xl mb-2">Start you adventure with us!</p>
            <p class="text-dark font-light text-xl md:pr-20">If you are happy with the proposed quote, you can make a deposit of <b class="font-bold text-primary">$<span>{{ totalFitPrice  }}</span>  USD</b>, which represents 30% of the cost of your trip.</p>

          </div>
          <div class="p-button self-end flex flex-wrap w-full xl:flex-nowrap md:mt-3 ">
              <paypal-button :amount="pricesList.paypalPrice" :bookingId="bookingId"/>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full py-6 md:py-8 flex h-full flex-wrap md:flex-nowrap md:gap-24" v-else>
    <div class="md:w-5/12 w-full h-full">
      <div class="border-4 border-dark shadow-2xl p-4 md:p-6 flex flex-wrap justify-center  rounded-xl">
        <div class="flex flex-col justify-center items-center">
            <p class="font-secondary text-3-4xl mb-2">{{ category }}</p>

            <svg width="70" height="16" viewBox="0 0 70 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5875 0L69.1588 10.8708L66.3134 15.9716L50.4742 6.70003L34.9642 15.0621L19.4218 6.6825L2.74625 16L0 10.8425L19.3734 0.0175261L34.9642 8.42325L50.5875 0Z" fill="#F19F00"/>
            </svg>
        </div>

        <div class="flex w-full mt-3 justify-between" v-if="pricesList">
          <div class="w-auto text-center" v-if="pricesList.sgl">
              <p class="uppercase text-md block text-center">single</p>
              <span class="uppercase font-primary font-black text-2xl text-primary"> <span>{{pricesList.sgl}}</span> USD</span>
          </div>
          <div class="w-auto text-center" v-if="pricesList.dbl">
              <p class="uppercase text-md block text-center">double</p>
              <span class="uppercase font-primary font-black text-2xl text-primary"> <span>{{pricesList.dbl}}</span> USD</span>
          </div>
          <div class="w-auto  text-center" v-if="pricesList.tpl">
              <p class="uppercase text-md block text-center">triple</p>
              <span class="uppercase font-primary font-black text-2xl text-primary"> <span>{{pricesList.tpl}}</span> USD</span>
          </div>
        </div>

        <div class="flex w-full mt-6 justify-center">
          <a class="font-light border border-dark bg-white text-md py-2 px-4 w-full hover:bg-primary hover:text-dark transition-all hover:border-primary rounded-lg text-center" @click="viewHotels">{{tr('View')}} {{category}} {{tr('Hotels')}}</a>
        </div>

      </div>  
    </div>
    <div class="md:w-7/12 w-full mt-6 md:mt-0">
      <div class="border-4 border-light-gray bg-light-gray shadow-xl p-4 md:p-6 flex flex-wrap justify-start flex h-full items-start rounded-xl">
        <div>
          <p class="font-secondary text-2xl mb-2">{{tr('Start you adventure with us!')}}</p>
          <p class="text-dark font-light text-2xl md:pr-20">
            {{tr('If you are happy with the proposed quote, you can make a deposit of')}} 
            <b class="font-bold text-primary"><span>{{ payment }}</span> USD</b>, 
            {{tr('which represents 30% of the cost of your trip.')}}</p>

        </div>
        <div class="p-button flex flex-wrap w-full md:flex-nowrap md:mt-3 justify-content-center">
            <paypal-button :amount="pricesList.paypalPrice" :bookingId="bookingId" v-if="showPaypal === 'true'" />
           <!--  <img src="@/assets/trip/img/cards.svg" class="mx-auto"> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import paypalButton from "@/components/tools/PaypalButton.vue"
import quoteCard from "@/components/itinerary/QuoteCard.vue"
import * as _ from 'lodash';
export default {
  name: 'PriceBox',
  data () {
    return {
      showPaypal: process.env.VUE_APP_SHOW_PAYPAL
    }
  },
  props: {
  },
  components: {
    'paypal-button': paypalButton,
    'quote-card': quoteCard,
  },
  computed: {
    fitPricesLength() {
      return this.fitPrices.length;
    },
    hotels () {
      return this.$store.state.hotels;
    },
    booking () {
      return this.$store.state.booking;
    },
    prices () {
      return this.$store.state.prices;
    },
    category () {
      if (this.hotels) {
        let rows = _.uniqBy(this.hotels, (r) => r.ClassCode);
        if (rows.length == 1) {
          return rows[0].ClassName;
        }
      }
      return '';
    },
    payment () {
      if (this.booking && this.booking.prices) {
        let v = this.booking.prices.tpl_total_price * 0.3 
        return v.toFixed(0);
      } 
      return ''
    },
    bookingId () {
      const params = new URLSearchParams(window.location.search);
      const bookingId = params.get("ID");
      return bookingId;
    },
    pricesList () {
      let res = {};
      if (this.prices && this.prices.length > 0) {  
        let row = this.prices[0];
        let result = row.PaxRange.split("+");
        res.sgl = Math.round(row.Sell + row.SellSingleSupp);
        res.dbl = Math.round(row.Sell);
        res.tpl = Math.round(row.Sell - row.SellTripleReduction);
        res.paypalPrice = Math.round((res.dbl * 0.3) * (parseInt(result[0]) + parseInt(result[1])))
      }
      return res;
    },
    bookingType () {
      return window.location.pathname.includes("fit") ? "FIT" : "PCM"
    },
    totalFitPrice (){
      const total = this.fitPrices?.reduce((acc, curr) => acc + curr.PriceTotal, 0)
      return  Math.round(total * 0.3)
    },
    fitPrices () {
      const res = this.$store.state.fitPrices
      if (this.fitPaxDetail != 'SI') res.length = 1
      return res
    },
    fitPaxDetail () {
      return this.$store.state.fitPaxDetail
    },
  },
  methods: {
    viewHotels () {
      let route = this.$router.resolve({ name: 'Hotels', params: this.$route.query })
      route.href = route.href + '?' + new URLSearchParams(this.$route.query).toString();
      window.open(route.href, '_blank');
    },
    tr(key) {
      return this.$root.tr(key);
    }
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
@media (min-width: 768px) {
    .mr2 {
        margin-right: 2rem !important;
    }
}
.hidden-parent {
  display: contents;
}
.p-button{
  justify-content: center;
}
</style>