<template>
    <swiper v-if="images.length > 0">
        <swiper-slide v-for="(image, index) of loadedImages" :key="image.id" class="h-96">
            <img v-if="image.data"
                 :src="image.data"
                 :class="imgClass" 
                 class="w-full h-full object-center object-cover"
                 alt="Day image">
            <Slide :index="index" :length="images.length"></Slide>
        </swiper-slide>
    </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import Slide from '@/components/itinerary/SwiperSlide.vue';
const api = window.api;

export default {
    name: 'DaySwiper',
    props: {
        images: Array,
        imgClass: String
    },
    data() {
        return {
            loadedImages: []
        }
    },
    mounted () {
    },
    components: {
        Swiper,
        SwiperSlide,
        Slide,
    },
    methods: {
    },
    watch: {
        images: {
            async handler(newImages) {
                this.loadedImages = await api.loadImages(newImages);
            },
            immediate: true
        }
    },
    created() {
        // Remove this
    }
};
  </script>