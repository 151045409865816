<template>
  <div
    class="w-full md:w-6/12 md:pr-20 mb-12 md:mb-0 flex items-center flex-wrap"
  >
    <div class="w-32 h-32 mr-7">
      <img :src="userData.ProfileImageUrl" class="rounded-full border-2 border-primary h-full object-cover" />
    </div>
    <div class="w-auto mt-3 md:mt-0">
      <p class="text-md uppercase">Your Guest Relation</p>
      <p class="font-black text-3xl">{{fullName}}</p>
    </div>
    <div class="w-full">
      <p class="text-dark font-normal text-md w-full mt-4 md:mt-2">
        {{userData.Description}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
    name: 'gr-section-why', 
    props: ['userData'],
    mounted () {
    },
    computed: {
        fullName () {
            return this.userData.FirstName + ' ' + this.userData.LastName
        }
    }
}
</script>