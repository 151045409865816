<template>
    <section>
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6">

          <div class="w-full pt-10">
              <h2 class="font-secondary text-2xl md:text-3xl mb-8">
              {{tr('Itinerary')}}
            </h2>
            <div class="w-full pt-4">
              <div v-for="(d, index) of dayList" :key="index">
                <Day :day="d" v-if="d.services.length == 1"></Day>
                <DayServices :day="d" v-else></DayServices>
              </div>  
            </div>
          </div>
      </div>
    </section>

</template>
<script>
import Day from '@/components/itinerary/Day.vue';
import DayServices from '@/components/itinerary/DayServices.vue';
import * as _ from 'lodash';
var moment = require('moment');

export default {
  name: 'DayList',
  props: {
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    languageNotes () {
      return this.$store.state.languageNotes;
    },
    services () {
      return this.$store.state.services
    },
    dayList () {
      let res = [];
      if (this.services) {
        let days = _.map(this.services, (e) => {
          let d = moment(e.date, 'YYYY-MM-DD');
          return {
            date: d,
            dateString: d.format('YYYY-MM-DD'),
            title: e.Titulo || '',
            code: e.OPT_ID,
            description: e.Descripcion || '',
            location: e.LocationCode,
            locationName: e.LocationName,
          }
        });
        let unique = _.uniqBy(days, 'dateString');
        unique.sort((a, b) => {
          if (a.dateString > b.dateString) return 1;
          if (a.dateString < b.dateString) return -1;
          return 0
        });
        let firstDate = unique[0]? unique[0].date: null;
        res = _.map(unique, (r) => {
            return {
              date: r.date.locale(this.$root.langCode).format('DD MMM'),
              dateLong: `${r.date.locale(this.$root.langCode).format('dddd DD MMM YYYY')}`.toUpperCase(),
              dateString: r.dateString,
              dayNr: r.date.diff(firstDate, 'day') + 1,
              services: _.map(_.filter(days, (d) => {
                  return d.dateString == r.dateString;
                }), (t) => {
                return {
                  title: t.title,
                  code: t.code,
                  description: t.description,
                  location: t.location,
                  locationName: t.locationName,
                }
              })
            }
        })
      }
      return res;

    }
  },
  data () {
    return {
    }
  },
  components: {
    Day,
    DayServices,
  },
  methods: {
    tr(key) {
      return this.$root.tr(key);
    }
  }
}
</script>
