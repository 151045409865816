<template>
  <section class="bg-primary h-50vh md:h-70vh w-full pt-4 relative pb-8 main-hero  bg-cover bg-center bg-no-repeat pt-6 md:pt-12 pb-8 md:pb-20" :style="imgBack">
    <div class="max-w-screen-2xl mx-auto px-4 flex relative z-10 flex-col flex-wrap justify-between h-full ">
      <div class=" w-full">
        <div class="site-logo">
          <a href="#"><img src="@/assets/trip/img/logo.svg"></a>
        </div>
      </div>
      <div class="w-full ">
        <h1 class="md:w-6/12 text-white font-secondary leading-tight text-4xl md:text-5-6xl font-normal md:pr-16 mb-3 text-left">{{category}} {{tr('Hotels')}} </h1>
        <p class="text-white font-light text-lg md:text-1/3xl">{{tr('Selected hotels for')}} <span v-html="paxName"></span></p>
      </div>
    </div>
  </section>

</template>

<script>
import * as _ from 'lodash';
const api = window.api;
export default {
  name: 'HotelsHeader',
  props: {
  },
  data () {
    return {
        bghotels: null,
        imgBack: null
    }
  },
  async mounted () {
    await this.getImagePath();
    this.imgBack  = {
        backgroundImage: "url(" + this.bghotels + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
    };
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    bookingCode () {
      return this.$store.state.query.bookingRef;
    },
    tripName () {
      if (this.booking) return this.booking.NombreDelViaje;
      return ''
    },
    category () {
      if (this.booking && this.booking.Itinerario && this.booking.Itinerario.LineaDetalle) {
        let rows = _.filter(this.booking.Itinerario.LineaDetalle, (r) => {
          if (r.CodigoTipoDeServicio == 'AC') return true;
          return false;
        })
        rows = _.uniqBy(rows, (r) => r.CodigoClase);
        if (rows.length == 1) {
          return rows[0].NombreClase;
        }
      }
      return '';
    },
    paxName () {
      return  this.$store.state.paxName;
    },
  },
  asyncComputed: {
    async image () {
      this.loadedImages = await api.loadImages([{url: this.coverImg}]);
      const binaryData = this.loadedImages[0].data;
      return {
        backgroundImage: `url(${binaryData})`,
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
      };
    }
  },
  methods: {
    async getImagePath () {
        await Promise.resolve(import(`@/assets/img/bghoteles.png`)).then((value) => {
            this.bghotels = value.default;
        });
    },
    tr (key) {
      return this.$root.tr(key);
    }

  }

}
</script>
