<template>
  <div
    ref="paypalButtonContainer"
    class="w-1/2 flex items-center justify-center"
  ></div>
</template>

<script>
/* global paypal */

import { loadScript } from "@paypal/paypal-js";
export default {
  name: "paypal-button",
  props: {
    amount: {
      type: Number,
      required: true,
    },
    bookingId: {
      type: String,
    },
  },
  mounted() {
    this.setClientId();
    this.initializePayPalButton();
    console.log("bookingId", this.bookingId);
  },
  methods: {
    async initializePayPalButton() {
      const paypalClientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
      try {
        await loadScript({ "client-id": paypalClientId });
        paypal
          .Buttons({
            style: {
              layout: "horizontal",
              tagline: false,
              color: "black",
              label: "paypal",
              shape: 'rect',
            },
            createOrder: async () => {
              try {
                const params = new URLSearchParams(window.location.search);
                const PCM_NAME = params.get("Code");
                const headers = {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                };
                const response = await window.api.post(
                  "/api/paypal/pay",
                  JSON.stringify({
                    amount: this.amount,
                    currency: "USD",
                    bookingId: this.bookingId,
                    tripName: PCM_NAME,
                  }),
                  headers
                );
                return response.key;
              } catch (error) {
                console.error("Error creating order:", error);
              }
            },
            onApprove: async (data, actions) => {
              return actions.order.capture().then(async (details) => {
                try {
                  const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  };
                  const response = await window.api.post(
                    "/api/paypal/payment/success",
                    JSON.stringify({ data: details }),
                    headers
                  );
                  return response.key;
                } catch (error) {
                  console.error("Error proccesing order:", error);
                }
              });
            },
            onError: (err) => {
              console.error("Error during transaction", err);
            },
          })
          .render(this.$refs.paypalButtonContainer);
      } catch (error) {
        console.error("Error loading PayPal SDK", error);
      }
    },
    setClientId() {
      this.paypalClientId = process.env.PAYPAL_CLIENT_ID;
    },
    cleanHTML(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  },
  },
};
</script>
