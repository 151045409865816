<template>
    <div class="w-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 items-end border-b-2 border-[#4C535966]">
      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Select airline</label>
        

        <select id="airline" v-model="f.airline"
          class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
            <option v-for="(c, i) of airlinesJSON" :key="i" :selected="c.name == f.airline">{{ c.name }}</option>
          </select>
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Flight number</label>
        <input v-model="f.number" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>

      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Reservation Airline code</label>
        <input v-model="f.reservationCode" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
      </div>


      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
        <div class="mb-5" style="position: relative;">
          <label class="block mb-2 text-sm font-medium text-gray uppercase">Departure date</label>
          <Datepicker :teleport="true" :value="f.departureDate" @selected="setDepartureDate" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-1 "></Datepicker>
        </div>

        <div class="mb-5" style="position: relative;">
          <label class="block mb-2 text-sm font-medium text-gray uppercase">Arrival date</label>
          <Datepicker :teleport="true" :value="f.arrivalDate" @selected="setArrivalDate" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-1 "></Datepicker>
        </div>
        
      </div>
      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
          <div class="mb-5">
            <label  class="block mb-2 text-sm font-medium text-gray uppercase">Departure city</label>
            <input v-model="f.departureCity" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
          </div>

          <div class="mb-5">
            <label  class="block mb-2 text-sm font-medium text-gray uppercase">Arrival city</label>
            <input v-model="f.arrivalCity" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
          </div>

      </div>

      <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
          <div class="mb-5">
            <label class="block mb-2 text-sm font-medium text-gray uppercase">Departure time</label>
            <div class="relative w-full">
                
                <input v-model="f.departureTime" @change="update" type="time" id="time" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3" min="09:00" max="18:00" value="00:00" />
            </div>
          
          </div>
          <div class="mb-5">
            <label class="block mb-2 text-sm font-medium text-gray uppercase">Arrival time</label>
            <div class="relative w-full">
                
                <input v-model="f.arrivalTime" @change="update" type="time" id="time" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3" min="09:00" max="18:00" value="00:00" />
            </div>
          
          </div>

      </div>
    </div>
</template>

<script>
var moment = require('moment');
import Datepicker from 'vuejs3-datepicker';
let airlinesJSON = require(`@/assets/airlines.json`);
export default {
  name: 'BookingFromFlight',
  data() {
    return {
      f: {},
      airlinesJSON: airlinesJSON
    }
  },
  components: {
    Datepicker
  },
  mounted () {
    this.f = Object.assign({}, this.flight);
  },
  props: {
    flight: Object
  },
  methods: {
    update () {
      this.$emit('update', this.f)
    },
    setDepartureDate (date) {
      this.f.departureDate = moment(date).format('YYYY-MM-DD');
      console.log(this.f.departureDate)
      this.update();
    },
    setArrivalDate (date) {
      this.f.arrivalDate = moment(date).format('YYYY-MM-DD');
      this.update();
    } 
  }
}
</script>

