<template>
    <section>
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6">
        <template v-if="!hidePrices">
          <div class="w-full md:w-2/3 pt-10">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
              {{tr('Prices per person')}}
            </h2>
          </div>
          <PriceBox></PriceBox>
        </template>
        <PriceTerms></PriceTerms>
      </div>
    </section>
</template>
<script>
import PriceBox from '@/components/itinerary/PriceBox.vue';
import PriceTerms from '@/components/itinerary/PriceTerms.vue';
//import AccordTest from '@/components/itinerary/AccordTest.vue';

export default {
  name: 'TripPricing',
  props: {
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    hidePrices () {
      return this.$store.state.hidePrices
    }
  },
  data () {
    return {
    }
  },
  components: {
    PriceBox,
    PriceTerms,
    //AccordTest
  },
  methods: {
    tr(key) {
      return this.$root.tr(key);
    }
  }
}
</script>
