<template>
  <div
    class="border-5 border-dark shadow-2xl p-4 md:p-6 flex flex-wrap justify-center rounded-xl"
  >
    <div class="flex flex-col justify-center items-center">
      <p class="font-secondary text-2xl mb-2">Comfort</p>

      <svg
        width="70"
        height="16"
        viewBox="0 0 70 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.5875 0L69.1588 10.8708L66.3134 15.9716L50.4742 6.70003L34.9642 15.0621L19.4218 6.6825L2.74625 16L0 10.8425L19.3734 0.0175261L34.9642 8.42325L50.5875 0Z"
          fill="#F19F00"
        ></path>
      </svg>
    </div>

    <div class="flex w-full mt-3 justify-center">
      <div class="w-auto text-center pt-2">
        <p class="uppercase text-md block text-center">double</p>
        <span class="uppercase font-primary font-black text-2xl text-primary"
          >{{personPrice}} USD</span
        >
      </div>
    </div>

    <div class="flex w-full mt-6 justify-center">
      <a
        class="font-light border border-dark bg-white text-md py-2 px-4 w-full hover:bg-primary hover:text-dark transition-all hover:border-primary rounded-lg text-center"
        >View Comfort Hotels</a
      >
    </div>
  </div>
</template>
<script>
export default {
    name: 'quote-card',
    props: ['personPrice', 'roomType', 'baseName'],
}
</script>
<style>
@media (min-width: 768px) {
    .md\:w-3\/12 {
        width: 25% !important;
    }
}
</style>